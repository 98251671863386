import React from 'react';
import 'react-vertical-timeline-component/style.min.css';
import {
  PageContainer,
  PageTitle,
  SectionContainer,
  SectionTitle,
  Text
} from '../../../theme/GlobalStyles';
import {
  SectionImgEicu,
  ImgEicu,
  ImgZoom,
  ImgCartaz,
  Article,
  ArticleTitle,
  ArticleText,
  RegulationText
} from './styled';
import Translator from '../../../i18n/Translator';
import EicuLogo from '../../../assets/images/eicu/logo-eicu.png';
import ContestCartaz from '../../../assets/images/eicu/cartaz_completo.png';

export default function ContestPage() {
  return (
    <PageContainer>
      <PageTitle>
        <Translator path="eicu.contest.title" />
      </PageTitle>
      <SectionImgEicu>
        <ImgEicu src={EicuLogo} loading="lazy" alt="eicu-logo" />
      </SectionImgEicu>
      <SectionContainer id="contest">
        <Text>
          <Translator path="eicu.contest.content" />
        </Text>
        <ImgZoom>
          <ImgCartaz src={ContestCartaz} loading="lazy" alt="foundation" />
        </ImgZoom>
        <br /> <br />
      </SectionContainer>
      <SectionContainer id="regulation">
        <SectionTitle>
          <Translator path="eicu.contest.regulation" />
        </SectionTitle>
        <RegulationText>
          <Article>ARTIGO 1º</Article>
          <ArticleTitle>Objeto / Disposições gerais</ArticleTitle>
          <ArticleText>
            <li>
              O Concurso de Composição Coral, integrado no XV Encontro
              Internacional de Coro Universitários (EICU), tem como objetivo
              promover a composição de novas obras corais portuguesas para coro
              misto.
            </li>
            <li>
              Este concurso é organizado pelo Coro Misto da Universidade de
              Coimbra (CMUC).
            </li>
          </ArticleText>

          <Article>ARTIGO 2º</Article>
          <ArticleTitle>Requisitos para participar</ArticleTitle>
          <ArticleText>
            <li>
              A competição é aberta a compositores de nacionalidade portuguesa
              ou residentes em Portugal.
            </li>
            <li>A idade mínima de participação é de 18 anos.</li>
          </ArticleText>

          <Article>ARTIGO 3º</Article>
          <ArticleTitle>Obras a concurso</ArticleTitle>
          <ArticleText>
            <li>
              Caso a obra contenha texto, deverá ser em português ou escrito por
              autor de país de língua oficial portuguesa.
            </li>
            <li>
              A obra deverá ser escrita para coro misto e de dificuldade
              adequada ao Coro Misto da Universidade de Coimbra (consultar a
              página do{' '}
              <a href="https://www.youtube.com/@coro_misto_uc">Youtube</a>).
            </li>
            <li>A obra deverá ter uma duração máxima de 5 minutos.</li>
            <li>A obra não pode ter sido estreada ou editada anteriormente.</li>
            <li>
              A composição deverá ser para execução a capella ou com
              acompanhamento de piano. Poderá ainda haver percussão corporal.
            </li>
          </ArticleText>

          <Article>ARTIGO 4º</Article>
          <ArticleTitle>Submissão</ArticleTitle>
          <ArticleText>
            <li>
              Cada concorrente deverá preencher o seguinte{' '}
              <a href="https://forms.gle/nN6iegUrKZQy54Ff7">formulário</a>.
              <ol type="a">
                <li>Nome completo</li>
                <li>Nome artístico</li>
                <li>E-mail</li>
                <li>
                  Comprovativo de nacionalidade portuguesa ou de residência em
                  Portugal
                </li>
                <li>PDF da obra sem elementos identificativos do autor</li>
                <li>
                  Versão final do PDF da obra com elementos identificativos do
                  autor
                </li>
                <li>Áudio MIDI da obra (opcional)</li>
                <li>
                  Comprovativo da permissão para uso do texto (se a obra tiver
                  texto e este não for de domínio público)
                </li>
              </ol>
            </li>
            <li>
              Cada concorrente poderá submeter <b>uma</b> obra (apenas a última
              submissão será considerada).
            </li>
          </ArticleText>

          <Article>ARTIGO 5º</Article>
          <ArticleTitle>Prazo para entrega</ArticleTitle>
          <ArticleText>
            <li>
              Apenas serão consideradas submissões até{' '}
              <b>31 de agosto de 2024</b>.
            </li>
          </ArticleText>

          <Article>ARTIGO 6º</Article>
          <ArticleTitle>Júri</ArticleTitle>
          <ArticleText>
            <li>
              O júri será composto por:
              <ul>
                <li>Rodrigo Carvalho</li>
                <li>Susana Milena</li>
                <li>Gonçalo Lourenço</li>
              </ul>
            </li>
          </ArticleText>

          <Article>ARTIGO 7º</Article>
          <ArticleTitle>Avaliação das obras a concurso</ArticleTitle>
          <ArticleText>
            <li>Apenas serão avaliadas as obras que cumpram os requisitos.</li>
            <li>
              As obras serão avaliadas após um processo de anonimização dos seus
              autores.
            </li>
            <li>
              A avaliação do júri é feita exclusivamente com base na obra
              enviada.
            </li>
            <li>
              Ao júri reserva-se o direito de não atribuir algum dos prémios, se
              considerar que não estão reunidos os requisitos mínimos de
              qualidade, ou de distribuir os prémios de forma diferente.
            </li>
            <li>A decisão do júri é final e inapelável.</li>
          </ArticleText>

          <Article>ARTIGO 8º</Article>
          <ArticleTitle>Resultados</ArticleTitle>
          <ArticleText>
            <li>
              Até dia 30 de setembro, o júri decidirá as 3 obras finalistas,
              sendo a decisão divulgada aos finalistas por e-mail e
              posteriormente através das redes sociais e do{' '}
              <a href="https://www.cmuc.pt">site</a> do CMUC.
            </li>
            <li>
              As obras finalistas serão estreadas pelo CMUC no concerto de
              encerramento do XV EICU, no dia <b>15 de dezembro de 2024</b>.
            </li>
            <li>
              Os autores das obras finalistas serão convidados a estar presentes
              na estreia das suas obras.
            </li>
          </ArticleText>

          <Article>ARTIGO 9º</Article>
          <ArticleTitle>Prémios</ArticleTitle>
          <ArticleText>
            <li>
              Serão atribuídos prémios às obras de melhor qualidade por decisão
              exclusiva do júri:
              <ul>
                <li>1º Prémio: 750€</li>
                <li>2º Prémio: 250€</li>
                <li>3º Prémio: 150€</li>
              </ul>
            </li>
            <li>
              As 3 obras finalistas serão interpretadas pelo CMUC e gravadas
              vídeo e fonograficamente.
            </li>
            <li>
              Para além disso, será ainda atribuído um prémio por votação do
              público, de caráter não monetário, que será decidido na Gala de
              Encerramento do XV EICU.
            </li>
          </ArticleText>

          <Article>ARTIGO 10º</Article>
          <ArticleTitle>Direitos da obra</ArticleTitle>
          <ArticleText>
            <li>
              Ao participar, os autores cedem os direitos ao CMUC para
              interpretar e para gravar vídeo e fonograficamente as obras e
              divulgá-las.
            </li>
            <li>
              O autor compromete-se a não cobrar direitos de autor pela
              interpretação da obra ao CMUC.
            </li>
            <li>
              O autor mantém os direitos a interpretar futuramente a sua obra.
            </li>
          </ArticleText>

          <Article>ARTIGO 11º</Article>
          <ArticleTitle>Tratamento de dados pessoais</ArticleTitle>
          <ArticleText>
            <li>
              Assegura-se que os dados pessoais serão tratados para o exclusivo
              efeito deste concurso.
            </li>
          </ArticleText>
        </RegulationText>
      </SectionContainer>
    </PageContainer>
  );
}
